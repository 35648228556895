<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row class="my-3">
      <v-col>
        <v-btn
          color="primary"
          @click.stop="dialogs.create = true"
        >
          Crear Alimento
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="4"
      >
        <v-select
          v-model="filters.food_group"
          :items="foodGroups"
          label="Grupo Alimenticio"
          clearable
        />
      </v-col>
      <v-col
        lg="4"
      >
        <v-select
          v-model="filters.portion"
          :items="portions"
          label="Porcion"
          clearable
        />
      </v-col>
      <v-col
        lg="4"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :search="search"
          :page.sync="page"
          light
        >
          <template v-slot:[`item.type`]="{ item }">
            <span
              v-if="item.type in portionTypes"
              :class="portionTypes[item.type].class"
            >
              {{ portionTypes[item.type].text }}
            </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span :class="item.status ? 'success--text' : 'error--text' ">
              <b>
                {{ item.status ? 'Activo' : 'Inactivo' }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              :color="item.status ? 'error' : 'success' "
              @click="setStatus(item.id, !item.status)"
            >
              {{ item.status ? 'mdi-close-circle' : 'mdi-check-circle' }}
            </v-icon>
            <v-icon
              @click="updateDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogs.create"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Crear Alimento
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col lg="12">
              <v-text-field
                v-model="forms.create.title"
                label="Titulo"
              />
            </v-col>
            <v-col lg="6">
              <v-autocomplete
                v-model="forms.create.food_group"
                label="Selecciona el grupo alimenticio"
                type="text"
                :items="foodGroups"
              />
            </v-col>
            <v-col lg="6">
              <v-autocomplete
                v-model="forms.create.type"
                label="Selecciona el tipo de porcion"
                type="text"
                :items="portions"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-for="(error, i) in forms.create.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="create"
          >
            Crear
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.create = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.update"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Actualizar Alimento
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="forms.update.title"
                label="Titulo"
              />
            </v-col>
            <v-col lg="6">
              <v-autocomplete
                v-model="forms.update.food_group"
                label="Selecciona el grupo alimenticio"
                type="text"
                :items="foodGroups"
              />
            </v-col>
            <v-col lg="6">
              <v-autocomplete
                v-model="forms.update.type"
                label="Selecciona el tipo de porcion"
                type="text"
                :items="portions"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                v-for="(error, i) in forms.update.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="update"
          >
            Actualizar
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.update = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { isEmpty } from 'validator'
  export default {
    data () {
      return {
        headers: [
          { text: 'Titulo', value: 'title' },
          { text: 'Grupo Alimenticio', value: 'group.title' },
          { text: 'Porcion', value: 'type' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', value: 'action' },
        ],
        portions: [
          { text: 'Proteina y Lacteos', value: 'PL' },
          { text: 'Carbohidratos', value: 'CARB' },
          { text: 'Lacteos', value: 'LACT' },
          { text: 'Proteinas', value: 'PROT' },
          { text: 'Vegetales', value: 'VEG' },
          { text: 'Frutas', value: 'FRUIT' },
          { text: 'Grasas Saludables', value: 'FAT' },
          { text: 'Postre', value: 'POSTRE' },
          // { text: 'Agua', value: 'WATER' },
        ],
        foodGroups: [],
        portionTypes: {
          PL: { text: 'Proteina y Lacteos', class: 'color-prot' },
          CARB: { text: 'Carbohidratos', class: 'color-carb' },
          LACT: { text: 'Lacteos', class: 'color-lact' },
          PROT: { text: 'Proteina', class: 'color-prot' },
          VEG: { text: 'Vegetales', class: 'color-veg' },
          FRUIT: { text: 'Frutas', class: 'color-fruit' },
          FAT: { text: 'Grasas Saludables', class: 'color-fat' },
          POSTRE: { text: 'Postre', class: 'color-postre' },
          WATER: { text: 'Agua', class: '' },
        },
        items: [],
        page: 5,
        filters: {
          portion: null,
          food_group: null,
        },
        search: null,
        dialogs: {
          create: false,
          update: false,
        },
        forms: {
          create: {
            title: '',
            food_group: '',
            type: '',
            errors: [],
          },
          update: {
            id: '',
            title: '',
            food_group: '',
            type: '',
            errors: [],
          },
        },
      }
    },
    computed: {
      filteredItems () {
        return this.items.filter((item) => {
          let valido = true
          if ((this.filters.food_group && item.group) && this.filters.food_group !== item.group.id) {
            valido = false
          }
          if (this.filters.portion && this.filters.portion !== item.type) {
            valido = false
          }
          if (this.search && !item.title.toLowerCase().includes(this.search.toLowerCase())) {
            valido = false
          }
          return valido
        })
      },
    },
    watch: {
      search () {
        this.page = 1
      },
      'filters.food_group': function () {
        this.page = 1
      },
      'filters.portion': function () {
        this.page = 1
      },
    },
    async mounted () {
      await this.getItems()
      await this.getFoodGroups()
    },
    methods: {
      async getItems () {
        this.items = await this.$api.Food.list()
      },
      async getFoodGroups () {
        const items = await this.$api.FoodGroup.list()
        this.foodGroups = items.map(x => {
          return { text: x.title, value: x.id }
        })
      },
      validate (type) {
        let form = null
        if (type === 'create') {
          form = this.forms.create
        } else if (type === 'update') {
          form = this.forms.update
        }
        form.errors.splice(0, form.errors.length)
        if (isEmpty(form.title)) {
          form.errors.push('Debes ingresar el titulo')
        }
        if (isEmpty(`${form.food_group}`)) {
          form.errors.push('Debes seleccionar el grupo alimenticio')
        }
        if (isEmpty(`${form.type}`)) {
          form.errors.push('Debes seleccionar tipo de porcion')
        }
        return form?.errors?.length === 0
      },
      async create () {
        const valid = this.validate('create')
        if (!valid) {
          return
        }
        const form = this.forms.create
        const payload = {
          title: form.title,
          food_group_id: form.food_group,
          type: form.type,
          status: true,
        }
        try {
          const response = await this.$api.Food.create(payload)
          this.items.push(response)
          form.title = ''
          form.food_group = ''
          form.type = ''
          this.dialogs.create = false
        } catch (err) {
          const response = err.response
          const data = response.data
          form.errors.splice(0, form.errors.length)
          if (response.status === 400) {
            if ('msg' in data && data.msg === 'Already exists') {
              form.errors.push('El grupo alimenticio ya existe')
            }
          }
        }
      },
      async updateDialog (item) {
        const form = this.forms.update
        form.id = item.id
        form.title = item.title
        form.food_group = item.food_group_id
        form.type = item.type
        this.dialogs.update = true
      },
      async update () {
        const valid = this.validate('update')
        if (!valid) {
          return
        }
        const form = this.forms.update
        const payload = {
          title: form.title,
          food_group_id: form.food_group,
          type: form.type,
        }
        try {
          const response = await this.$api.Food.update(form.id, payload)
          const index = this.items.map(x => x.id).indexOf(form.id)
          this.items.splice(index, 1, response)
          this.dialogs.update = false
        } catch (err) {

        }
      },
      async setStatus (id, status) {
        const response = await this.$api.Food.setStatus(id, status)
        const index = this.items.map(x => x.id).indexOf(id)
        this.items.splice(index, 1, response)
      },
    },
  }
</script>
